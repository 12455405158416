<template>
    <BT-Blade-Items
        v-if="locationSelected"
        addBladeName="stock-consignment"
        bladeName="stock-consignments"
        :bladesData="bladesData"
        canAdd
        canSearchLocal
        :dependantBlades="[{ bladeName: 'stock-consignment' }]"
        :getParams="getParams"
        :headers="[
            { text: 'CO#', value: 'consignmentNumber', csv: true, subtitle: 3, searchable: true, prefix: 'Consignment #' },
            { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth', csv: true, subtitle: 1, prefix: 'Due: ', searchable: true },
            { text: 'Customer', value: 'buyer.companyName', csv: true, title: 1, searchable: true },
            { text: 'Departure', value: 'departureLocation.locationName', csv: true, subtitle: 1, prefix: 'From: ', searchable: true },
            { text: 'Destination', value: 'destinationLocation', textFilter: 'toFamiliarLocationLine', csv: true, subtitle: 2, prefix: 'To: ', searchable: true }]"
        :hoverItemActions="false"
        navigation="stock-consignments"
        :refreshToggle="refreshToggle"
        title="Stock Consignments"
        useServerPagination>
        <template v-slot:toolbar>
            <v-toolbar-title>
                <BT-Entity
                    alternateText="Stock Consignments"
                    navigation="locations"
                    :itemValue="locationID"
                    itemText="locationName">
                    <template v-slot:prepend><span>Stock Consignments: </span></template>
                </BT-Entity>
            </v-toolbar-title>
        </template>
        <template v-slot:settings>
            <BT-Entity
                v-if="$canView('picking-settings')"
                ignoreID
                inline
                navigation="picking-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-divider />
                    <v-subheader>Actions</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Consigning</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <v-btn small icon :to="{ name: 'template-optimization-hub' }" title="Manage Locations Stock Is Supplied From">
                                    <v-icon small>mdi-routes</v-icon>
                                </v-btn>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.consignmentGuide)"
                                    v-model="item.consignmentTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon 
                                    v-model="item.consignmentGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Stock Provisioning</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.provisionGuide)"
                                    v-model="item.provisionStrategy"
                                    defaultValue="FirstInFirstOut"
                                    :toggleOptions="[
                                        { text: 'First In First Out', value: 'FirstInFirstOut', icon: 'mdi-refresh-circle' },
                                        { text: 'Freshest', value: 'Freshest', icon: 'mdi-sync-circle' }]"
                                    @change="save" />
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.provisionGuide)"
                                    v-model="item.provisionTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon 
                                    v-model="item.provisionGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Courier Consigning</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.dispatchRequestGuide)"
                                    v-model="item.dispatchRequestTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon
                                    v-model="item.dispatchRequestGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Archiving</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.archiveGuide)"
                                    v-model="item.archiveTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon 
                                    v-model="item.archiveGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider />
                    <v-subheader>Settings</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Must Full Provision Before Courier Consigning</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch
                                v-model="item.mustFullyProvision"
                                color="success"
                                :disabled="!isGlobalSetting(item.provisionGuide)"
                                @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Calculate Weight</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch v-model="item.calculateWeight" color="success" @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Calculate Packages</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch v-model="item.calculateConsignmentPackages" color="success" @change="save" />
                        </v-list-item-action>
                    </v-list-item>
                    
                    <v-divider />
                    <v-subheader>Packages</v-subheader>
                    <BT-Select-List
                        v-if="item.calculateConsignmentPackages"
                        @change="save"
                        v-model="item.consignmentPackageIDs"
                        chips
                        itemText="measurementName"
                        itemValue="id"
                        multiple
                        returnCSV
                        outlined
                        navigation="measurements"
                        key="12" />
                </template>
            </BT-Entity>
        </template>
        <template v-slot:actionsRight>
            <BT-Menu-Select
                
                icon="mdi-map-marker-radius"
                itemText="locationName"
                itemValue="id"
                navigation="locations"
                v-model="locationID" />
        </template>
        <template v-slot:itemActions="{ item }">
            <v-icon
                v-if="item.sourceHasChanged"
                class="warning--text"
                title="Order Changed">mdi-alert</v-icon>

            <BT-Dialog
                v-if="item.departureLocationID == null"
                icon="mdi-map-marker-check"
                text="Select Location"
                :item="item"
                small
                width="400"
                label="Supply Consignment From"
                title="Supply Consignment From"
                :getOnOpenAsync="getSupplyOptionsAsync"
                @ok="supplyConsignment">
                <template slot-scope="{ item }">
                    <BT-Select
                        v-if="item != null && item.consignment != null"
                        :navigation="item.anyLocation ? 'locations' : null"
                        :items="item.anyLocation ? null : item.locations"
                        v-model="item.locationID"
                        itemValue="id"
                        itemText="locationName"
                        hide-details
                        label="Departure Location" />
                    <v-checkbox v-model="item.anyLocation" dense label="Other Location" />
                    
                </template>
            </BT-Dialog>

            <BT-Single-Action
                v-if="item.performedOn == null && !item.isFullyProvisioned && item.departureLocationID != null"
                icon="mdi-clipboard-check-outline"
                :loading="item.loadingCount > 0"
                @click="provisionConsignment(item)"
                title="Auto Provision" />

            <BT-Single-Action
                v-if="!item.isSellerResolved && (item.performedOn != null || item.performedOn == null && !item.isDispatched && item.isFullyProvisioned && !item.sourceHasChanged && item.departureLocationID != null)"
                :loading="item.loadingCount > 0"
                icon="mdi-archive-arrow-down-outline"
                @click="archive(item)"
                title="Archive Consignment" />

            <BT-Single-Action
                v-if="item.departureLocationID != null && item.performedOn == null && !item.isDispatched"
                title="Proceed to next step of consignment - a courier consignment."
                :loading="item.loadingCount > 0"
                @click="postConsignmentFor(item)" />
            
            <BT-Single-Action
                v-if="!item.isTransfer && item.departureLocationID != null && item.performedOn == null"
                icon="mdi-close"
                :loading="item.loadingCount > 0"
                @click="cancelConsignment(item)"
                title="Cancel Consignment"
                class="error--text" />

        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Stock-Consignments-Blade',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTMenuSelect: () => import('~components/BT-Menu-Select.vue'),
        BTSingleAction: () => import('~components/BT-Single-Action.vue'),
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        BTToggleGuideIcon: () => import('~components/BT-Toggle-Guide-Icon.vue')
    },
    data: function() {
        return {
            bladeData: null,
            locationID: null,
            locationSelected: false,
            msg: null,
            refreshToggle: false
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    watch: {
        locationID() {
            this.refreshToggle = !this.refreshToggle;
        }
    },
    async mounted() {
        if (this.locationID == null || this.locationSelected == false) {
            
            try {
                this.locationID = this.selectedLocationID();
            }
            finally {
                this.locationSelected = true;
            }
        }
    },
    methods: {
        getParams(bladeData) {
            return { includeDetails: false, customerIDs: bladeData.data == null ? null : bladeData.data.customerID, locationID: this.locationID };
        },
        async getSupplyOptionsAsync(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var suggestionRes = await this.$BlitzIt.api.getAll('supply-location-suggestions', { ID: item.destinationLocationID, dueDate: item.dueDate });
                var suggestions = suggestionRes.data.data;
                return {
                    consignment: item,
                    locations: suggestions,
                    locationID: null,
                    anyLocation: false
                };
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
                return null;
            }
            finally {
                item.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        async supplyConsignment(res) {
            if (res != null && res.locationID != null) {
                res.consignment.departureLocationID = res.locationID;
                res.consignment.departureLocation = res.locations.find(x => x.id == res.locationID);

                try {
                    res.consignment.loadingCount += 1;
                    this.$forceUpdate();

                    var pRes = await this.$BlitzIt.store.patch('stock-consignments', res.consignment);
                    res.consignment.rowVersion = pRes.rowVersion;
                    res.consignment.isDispatched = pRes.isDispatched;
                }
                catch (err) {
                    res.consignment.errorMsg = this.extractErrorDescription(err);
                }
                finally {
                    res.consignment.loadingCount -= 1;
                    this.$forceUpdate();
                }
            }
        },
        async postConsignmentFor(po) {
            try {
                po.loadingCount += 1;
                this.$forceUpdate();

                var res = await this.$BlitzIt.store.patch('stock-consignments', Object.assign({}, po, { isDispatchRequest: true }));
                
                po.isDipatched = true;
                po.rowVersion = res.rowVersion;

                this.bladesData.openBlade({ bladeName: 'stock-consignment', data: { id: res.id } });
            }
            catch (err) {
                po.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                po.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        async archive(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                await this.$BlitzIt.store.patch('stock-consignments', { id: item.id, rowVersion: item.rowVersion, isArchiveRequest: true });
                this.$BlitzIt.store.deleteLocal('stock-consignments');
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        async cancelConsignment(consignment) {
            try {
                consignment.loadingCount += 1;
                this.$forceUpdate();

                await this.$BlitzIt.store.patch('stock-consignments', { id: consignment.id, rowVersion: consignment.rowVersion, departureLocationID: null });
                consignment.departureLocationID = null;
                consignment.departureLocation = null;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                consignment.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        async provisionConsignment(consignment) {
            try {
                consignment.loadingCount += 1;
                this.$forceUpdate();

                var res = await this.$BlitzIt.store.patch('stock-consignments', Object.assign({}, consignment, { autoProvision: true }));
                consignment.isFullyProvisioned = res.isFullyProvisioned;
                consignment.consignmentEntryItems = res.consignmentEntryItems;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                consignment.loadingCount -= 1;
                this.$forceUpdate();
            }
        }
    }
}
</script>